import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
/*-----------------------------------Import From Material Starts here--------------------------------------------------*/
import {
  Box,
  Container,
  Typography,
  Alert,
  AlertTitle,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
/*-----------------------------------Import From Material Ends here--------------------------------------------------*/
/*-----------------------------------Import From Common Files Ends here------------------------------------*/
import LanguageTranslation from "../../../components/Language/LanguageTranslation";
import TabView from "./InnerFiles/TabView";
import Result from "./InnerFiles/Result";
import ToolbarRelationship from "./InnerFiles/ToolbarRelationship";
import SnackBar from "../../../components/SnackBar";
import Page from "../../../components/Page";
import ErrorBoundary from "../../../ErrorBoundry";
import TypeObjectDialogSingleExternal from "../../../components/LayoutComponent/Common/Components/Relationship/TypeObjectDialogSingleExternal";
import ConfirmationDialogue from "../../../components/common/ConfirmationDialogue";
/*-----------------------------------Import From Common Files Ends here------------------------------------*/
/*-----------------------------------Import Action Files Starts here------------------------------------*/
import { fetchAppSettingsByAppIdAndCategory } from "../../../components/actions/appSettingsActions";
import { fetchStateAttributeWithoutPickList } from "../../../components/actions/attributesActions";
import { fetchRelationDataByID } from "../../../components/actions/relationshipsActions";
import { fetchContainerInstanceMembers } from "../../../components/actions/usersActions";
import ObjectPreview from "../../../components/LayoutComponent/ExternalTypes/ObjectPreview";
import {
  attachIntegrationRelationData,
  detachIntegrationRelationData,
  fetchAlreadyLinkedExternalRelationshipdataByObjectId,
} from "../../../components/actions/integrationApiActions";
/*-----------------------------------Import Action Files Ends here------------------------------------*/
import { WidgetApi } from "@intland/cb-widget-api";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const View = (props) => {
  const { classes } = useStyles();
  let { containerId, containerInstanceId, appId } = useParams();
  const dispatch = useDispatch();
  const myGrid = useRef([]);
  const [title, setTitle] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [relationshipId, setRelationshipId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [typeId, setTypeId] = useState("");
  const [parentTypeId, setParentTypeId] = useState("");
  const [objectId, setObjectId] = useState(31091);
  const [relnAttributes, setRelnAttributes] = useState([]);
  const [tabValue, setTabValue] = useState();
  const [relationData, setRelationData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [data, setData] = useState([]);
  const [tabError, setTabError] = useState(false);
  const [diasbledAttachment, setDiasbledAttachment] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [stateFieldVal, setStateFieldVal] = useState([]);
  const [dateFormat, setDateFormat] = useState("MMM d yyyy");
  const [dateTimeFormat, setDateTimeFormat] = useState("MMM d yyyy hh:mm a");
  const [toolbarLoader, setToolbarLoader] = useState(true);
  const [relationshipunique_multivalued, setRelationshipunique_multivalued] =
    useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [loader, setLoader] = useState(true);
  const [relnRef, setRelnRef] = useState({ relnId: "", type: "" });
  const [previewData, setPreviewData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [previewLoader, setPreviewLoader] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  //Snackbar
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [msg, setMsg] = useState("");
  //sorting , grouping and aggregate
  const [allOtherSettings, setAllotherSettings] = useState();
  const [showSorting, setShowSorting] = useState(false);
  const [sortingParameters, setSortingParameters] = useState([
    { selectedSorting: null, selectedDirection: null },
  ]);
  const [aggregatePosition, setAggregatePosition] = useState("Footer");
  const [groupByAttribute, setGroupByAttribute] = useState([]);
  const [showAggregate, setShowAggregate] = useState(false);
  const [aggregateParameters, setAggregateParameters] = useState([
    { selectedAggregate: null, selectedOperation: null },
  ]);
  const [screenError, setScreenError] = useState("");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const api = new WidgetApi(window, "widget-id", "*");
  api
    .getSelectedItem()
    .then((response) => {
      if (response && response.itemId) {
        setObjectId(response.itemId);
        setScreenError("");
      } else {
        setScreenError("This UI can be visible only from codebeamer tracker");
      }
    })
    .catch((err) => {
      setScreenError("This UI can be visible only from codebeamer tracker");
    });

  // const handleItemChange = (item) => {
  //   console.log("item ");
  //   console.log(item);
  //   const api2 = new WidgetApi(window, "widget-id", "*");
  //   api2.getConfig().then((response) => {
  //     console.log("getConfig ");
  //     console.log(response);
  //     const api3 = new WidgetApi(window, "widget-id", "*");
  //     api3.reloadItem(response).then((res) => {
  //       console.log("reloadItem ");
  //       console.log(res);
  //     });
  //   });
  // };

  useEffect(() => {
    // const cleanup = api.onSelectedItemChange(handleItemChange());
    //   return () => {
    //     const api1 = new WidgetApi(window, "widget-id", "*");
    //     api1
    //       .getSelectedItem()
    //       .then((response) => {
    //         console.log("selected ");
    //         console.log(response);
    //         if (response && response.itemId) {
    //           setObjectId(response.itemId);
    //           setScreenError("");
    //         } else {
    //           setScreenError(
    //             "This UI can be visible only from codebeamer tracker"
    //           );
    //         }
    //       })
    //       .catch((err) => {
    //         setScreenError("This UI can be visible only from codebeamer tracker");
    //       });
    //     cleanup(); // Cleanup when the component unmounts or dependencies change
    //   };
  }, []);

  useEffect(() => {
    const api5 = new WidgetApi(window, "widget-id", "*");
    api5.getConfig().then((response) => {
      console.log("getConfig11111 ");
      console.log(response);
      const api6 = new WidgetApi(window, "widget-id", "*");
      api6.reloadItem(response).then((res) => {
        console.log("reloadItem11111 ");
        console.log(res);
      });
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  /*------------------Get state value---------------------------*/
  useEffect(() => {
    if (typeId && typeId !== "") {
      dispatch(fetchStateAttributeWithoutPickList(typeId)).then((obj) => {
        if (obj.status === 200 && obj.data) {
          setStateFieldVal([obj.data]);
        }
      });
    }
  }, [typeId, attributes]);
  /*------------------Get state value---------------------------*/

  /*---------------------calling apis to fetch Date and date time format settings starts here--------------------*/
  useEffect(() => {
    if (appId != undefined && appId !== null && appId) {
      dispatch(fetchAppSettingsByAppIdAndCategory(appId, "dateformat")).then(
        (obj) => {
          if (obj.data) {
            if (
              obj.data &&
              obj.data.length !== 0 &&
              obj.data[0].appSettingDetails !== undefined &&
              obj.data[0].appSettingDetails !== null &&
              obj.data[0].appSettingDetails &&
              obj.data[0].appSettingDetails.length > 0 &&
              obj.data[0].appSettingDetails[0].value !== undefined &&
              obj.data[0].appSettingDetails[0].value !== null &&
              obj.data[0].appSettingDetails[0].value &&
              JSON.parse(obj.data[0].appSettingDetails[0].value) &&
              JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateFormat
            ) {
              setDateFormat(
                JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateFormat
              );
            }
          }
        }
      );
      dispatch(
        fetchAppSettingsByAppIdAndCategory(appId, "datetimeformat")
      ).then((obj) => {
        if (obj.data) {
          if (
            obj.data &&
            obj.data.length !== 0 &&
            obj.data[0].appSettingDetails !== undefined &&
            obj.data[0].appSettingDetails !== null &&
            obj.data[0].appSettingDetails &&
            obj.data[0].appSettingDetails.length > 0 &&
            obj.data[0].appSettingDetails[0].value !== undefined &&
            obj.data[0].appSettingDetails[0].value !== null &&
            obj.data[0].appSettingDetails[0].value &&
            JSON.parse(obj.data[0].appSettingDetails[0].value) &&
            JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateTimeFormat
          ) {
            setDateTimeFormat(
              JSON.parse(obj.data[0].appSettingDetails[0].value)[0]
                .dateTimeFormat
            );
          }
        }
      });
    }
  }, [appId]);

  /*----------------------------------------------------------------------*/

  /*------------------Get relationship details like name n info---------------------------*/
  useEffect(() => {
    if (relationshipId !== "") {
      dispatch(fetchRelationDataByID(relationshipId))
        .then((obj) => {
          if (obj.status === 200 && obj.data && obj.data.id) {
            setRelationData([obj.data]);
            setRelationshipunique_multivalued(obj.data.multivalue);
            setToolbarLoader(false);
          }
        })
        .catch((err) => {
          setToolbarLoader(false);
        });
    }
  }, [relationshipId]);
  /*------------------Get relationship details like name n info---------------------------*/

  /*------------------Attachment button enable/disable---------------------------*/
  useEffect(() => {
    if (relationshipunique_multivalued === false) {
      if (data.length > 0) {
        setDiasbledAttachment(true);
      } else {
        setDiasbledAttachment(false);
      }
    } else {
      setDiasbledAttachment(false);
    }
  }, [relationshipunique_multivalued, data]);
  /*------------------Attachment button enable/disable---------------------------*/

  /*------------------Getting users---------------------------*/
  useEffect(() => {
    if (
      containerId !== undefined &&
      containerId !== null &&
      containerId &&
      containerInstanceId !== undefined &&
      containerInstanceId !== null &&
      containerInstanceId
    ) {
      dispatch(
        fetchContainerInstanceMembers(
          parseInt(containerId),
          parseInt(containerInstanceId)
        )
      ).then((res) => {
        if (res.status === 200) {
          setAllUsers(res.data);
        } else {
          setAllUsers([]);
        }
      });
    }
  }, [containerId, containerInstanceId]);
  /*------------------Getting users---------------------------*/

  // useEffect(() => {
  //   if (
  //     allOtherSettings !== undefined &&
  //     allOtherSettings !== null &&
  //     allOtherSettings &&
  //     attributes !== undefined &&
  //     attributes !== null &&
  //     attributes &&
  //     attributes.length > 0
  //   ) {
  //     if (
  //       allOtherSettings.selectedGroupByAttribute !== undefined &&
  //       allOtherSettings.selectedGroupByAttribute !== null &&
  //       allOtherSettings.selectedGroupByAttribute &&
  //       allOtherSettings.selectedGroupByAttribute.length > 0
  //     ) {
  //       //setting dynamic columns according to the setting
  //       let columns = [];
  //       const columnvalue = allOtherSettings.selectedGroupByAttribute;
  //       if (
  //         columnvalue !== undefined &&
  //         columnvalue !== null &&
  //         columnvalue &&
  //         columnvalue.length > 0
  //       ) {
  //         for (let i = 0; i < columnvalue.length; i++) {
  //           for (let j = 0; j < attributes.length; j++) {
  //             if (columnvalue[i].id === attributes[j].id) {
  //               columns.push(attributes[j]);
  //             }
  //           }
  //         }
  //         setGroupByAttribute(columns);
  //       }
  //     } else {
  //       setGroupByAttribute([]);
  //     }

  //     if (
  //       allOtherSettings.showAggregate !== undefined &&
  //       allOtherSettings.showAggregate !== null &&
  //       allOtherSettings.showAggregate === true
  //     ) {
  //       setShowAggregate(true);
  //       if (
  //         allOtherSettings.aggregateParameters !== undefined &&
  //         allOtherSettings.aggregateParameters !== null &&
  //         allOtherSettings.aggregateParameters &&
  //         allOtherSettings.aggregateParameters.length > 0
  //       ) {
  //         const available = attributes.filter((val) => {
  //           return allOtherSettings.aggregateParameters.some((f) => {
  //             if (
  //               f.selectedAggregate !== undefined &&
  //               f.selectedAggregate !== null &&
  //               f.selectedAggregate &&
  //               f.selectedAggregate.id !== undefined &&
  //               f.selectedAggregate.id !== null &&
  //               f.selectedAggregate.id &&
  //               f.selectedOperation !== undefined &&
  //               f.selectedOperation !== null
  //             ) {
  //               return val.id === f.selectedAggregate.id;
  //             }
  //           });
  //         });
  //         if (available.length === 0) {
  //           setAggregateParameters([
  //             { selectedAggregate: null, selectedOperation: null },
  //           ]);
  //           setShowAggregate(false);
  //         } else {
  //           const availableAggregateParameters =
  //             allOtherSettings.aggregateParameters.filter((f) => {
  //               return available.some((val) => {
  //                 if (
  //                   f.selectedAggregate !== undefined &&
  //                   f.selectedAggregate !== null &&
  //                   f.selectedAggregate &&
  //                   f.selectedAggregate.id !== undefined &&
  //                   f.selectedAggregate.id !== null &&
  //                   f.selectedAggregate.id &&
  //                   f.selectedOperation !== undefined &&
  //                   f.selectedOperation !== null
  //                 ) {
  //                   if (val.id === f.selectedAggregate.id) {
  //                     f.selectedAggregate = val;
  //                     return f;
  //                   }
  //                 }
  //               });
  //             });
  //           setAggregateParameters(availableAggregateParameters);
  //         }
  //       } else {
  //         setAggregateParameters([
  //           { selectedAggregate: null, selectedOperation: null },
  //         ]);
  //       }
  //     } else {
  //       setShowAggregate(false);
  //     }

  //     if (
  //       allOtherSettings.showAggregate !== undefined &&
  //       allOtherSettings.showAggregate !== null &&
  //       allOtherSettings.showAggregate === true
  //     ) {
  //       if (
  //         allOtherSettings.selectedAggregatePosition !== undefined &&
  //         allOtherSettings.selectedAggregatePosition !== null &&
  //         allOtherSettings.selectedAggregatePosition &&
  //         allOtherSettings.selectedGroupByAttribute !== undefined &&
  //         allOtherSettings.selectedGroupByAttribute !== null &&
  //         allOtherSettings.selectedGroupByAttribute &&
  //         allOtherSettings.selectedGroupByAttribute.length > 0
  //       ) {
  //         setAggregatePosition(allOtherSettings.selectedAggregatePosition);
  //       } else {
  //         setAggregatePosition("Footer");
  //       }
  //     } else {
  //       setAggregatePosition("Footer");
  //     }
  //     if (
  //       allOtherSettings.showSorting !== undefined &&
  //       allOtherSettings.showSorting !== null &&
  //       allOtherSettings.showSorting === true
  //     ) {
  //       setShowSorting(true);
  //       if (
  //         allOtherSettings.sortingParameters !== undefined &&
  //         allOtherSettings.sortingParameters !== null &&
  //         allOtherSettings.sortingParameters &&
  //         allOtherSettings.sortingParameters.length > 0
  //       ) {
  //         const available = attributes.filter((val) => {
  //           return allOtherSettings.sortingParameters.some((f) => {
  //             if (
  //               f.selectedSorting !== undefined &&
  //               f.selectedSorting !== null &&
  //               f.selectedSorting &&
  //               f.selectedSorting.id !== undefined &&
  //               f.selectedSorting.id !== null &&
  //               f.selectedSorting.id &&
  //               f.selectedDirection !== undefined &&
  //               f.selectedDirection !== null
  //             ) {
  //               return val.id === f.selectedSorting.id;
  //             }
  //           });
  //         });
  //         if (available.length === 0) {
  //           setSortingParameters([
  //             { selectedSorting: null, selectedDirection: null },
  //           ]);
  //           setShowSorting(false);
  //         } else {
  //           const availableSortingParameters =
  //             allOtherSettings.sortingParameters.filter((f) => {
  //               return available.some((val) => {
  //                 if (
  //                   f.selectedSorting !== undefined &&
  //                   f.selectedSorting !== null &&
  //                   f.selectedSorting &&
  //                   f.selectedSorting.id !== undefined &&
  //                   f.selectedSorting.id !== null &&
  //                   f.selectedSorting.id &&
  //                   f.selectedDirection !== undefined &&
  //                   f.selectedDirection !== null
  //                 ) {
  //                   if (val.id === f.selectedSorting.id) {
  //                     f.selectedSorting = val;
  //                     return f;
  //                   }
  //                 }
  //               });
  //             });
  //           setSortingParameters(availableSortingParameters);
  //         }
  //       } else {
  //         setSortingParameters([
  //           { selectedSorting: null, selectedDirection: null },
  //         ]);
  //       }
  //     } else {
  //       setShowSorting(false);
  //     }
  //   }
  // }, [allOtherSettings, attributes]);

  const fetchData = () => {
    const dataTobeSend = {
      relationId: relationshipId,
      objectId: objectId,
      containerId: containerId,
      containerInstanceId: containerInstanceId,
      typeId: parseInt(parentTypeId),
    };
    dispatch(
      fetchAlreadyLinkedExternalRelationshipdataByObjectId(dataTobeSend)
    ).then((result) => {
      if (result !== undefined && result !== null && result) {
        if (
          result.status !== undefined &&
          result.status !== null &&
          result.status &&
          result.status === 200
        ) {
          if (result.data) {
            setData(result.data);
          }
          setLoader(false);
        } else {
          setLoader(false);
        }
      }
    });
  };

  const attacedLink = () => {
    setShowModal(true);
  };
  const deattacedLink = () => {
    setDeleteConfirmation(true);
  };

  /*--------------------For choosing column and excel export in the grid starts here----------------------*/
  const columnChooserclick = () => {
    setRelnRef({ ...relnRef, relnId: relationshipId, type: "columnChooser" });
  };

  const excelExport = () => {
    setRelnRef({ ...relnRef, relnId: relationshipId, type: "excelExport" });
  };
  const searchChange = (val) => {
    setRelnRef({
      ...relnRef,
      relnId: relationshipId,
      type: "search",
      val: val,
    });
  };
  /*--------------------For choosing column and excel export in the grid Ends here----------------------*/
  const closeAlert = () => {
    setAlert(false);
  };

  // Call save api to attach reln data
  const handleAttachData = (data) => {
    setShowModal(false);
    let dataToSave = [];
    data.map((resdat) => {
      dataToSave.push({
        forwardId: parseInt(objectId),
        backwardId: resdat.id,
        relationshipForwardId: parseInt(relationshipId),
      });
    });
    if (dataToSave.length > 0) {
      dispatch(attachIntegrationRelationData(dataToSave)).then((objData) => {
        if (objData.status === 200) {
          setAlert(true);
          setSeverity("success");
          setMsg("Successfully attached!");
          setLoader(true);
          fetchData();
        } else {
          if (objData.data) {
            setAlert(true);
            setSeverity("error");
            setMsg(objData.data);
            setLoader(true);
          }
        }
      });
    }
  };

  const deleteSelectedObjects = () => {
    let removedObjs = [];
    if (selectedRows) {
      selectedRows.map((remObj) => {
        if (remObj.externalRelationshipDataId) {
          removedObjs.push(remObj.externalRelationshipDataId);
        }
      });
    }
    if (removedObjs.length > 0) {
      dispatch(detachIntegrationRelationData(removedObjs)).then((remData) => {
        if (remData.status === 200) {
          setAlert(true);
          setSeverity("success");
          setMsg("Successfully deattached!");
          setLoader(true);
          fetchData();
        } else {
          if (remData.data) {
            setAlert(true);
            setSeverity("error");
            setMsg(remData.data);
            setLoader(true);
          }
        }
      });
    }
    setDeleteConfirmation(false);
  };

  const toggleSelect = (e) => {
    //------------------------- row click (not on checkbox)---------------------------------------
    if (e.name && e.name == "recordClick") {
      if (e.cellIndex !== 0) {
        if (selectedRows.length === 1) {
          //  myGrid.current.clearSelection();
          selectedRows.pop();
        }

        setVisibleSidebar(true);
      }
    }
    //------------------------- row click (not on checkbox)---------------------------------------
    //------------------------- row click on checkbox---------------------------------------
    if (e.name && e.name == "rowSelected") {
      if (Array.isArray(e.data)) {
        setSelectedRows([...e.data]);
      } else {
        setSelectedRows([...selectedRows, { ...e.data }]);
      }
    } else if (e.name && e.name == "rowDeselected") {
      if (Array.isArray(e.data)) {
        setSelectedRows([]);
        setVisibleSidebar(false);
      } else {
        setSelectedRows([
          ...selectedRows.filter((row) => e.data.id !== row.id),
        ]);
      }
    }
  };

  return (
    <ErrorBoundary>
      <SnackBar
        alert={alert}
        closeAlert={closeAlert}
        severity={severity}
        msg={msg}
      ></SnackBar>
      <Page
        className={classes.root}
        title={<LanguageTranslation key="TYPE" text="Types" />}
      >
        <Container maxWidth={false}>
          {screenError && screenError !== "" ? (
            <Box
              display="flex"
              flexDirection="column"
              height={"100%"}
              width={"100%"}
              style={{ marginTop: 40 }}
              justifyContent="center"
            >
              <Container maxWidth="md">
                <Typography align="center" color="primary" variant="h2">
                  <Alert severity="info">
                    <AlertTitle>{screenError}</AlertTitle>
                  </Alert>
                </Typography>
              </Container>
            </Box>
          ) : tabError ? (
            <Box
              display="flex"
              flexDirection="column"
              height={"100%"}
              width={"100%"}
              style={{ marginTop: 40 }}
              justifyContent="center"
            >
              <Container maxWidth="md">
                <Typography align="center" color="primary" variant="h2">
                  <Alert severity="info">
                    <AlertTitle>
                      You don't have any settings for this page. Please contact
                      your admin.
                    </AlertTitle>
                  </Alert>
                </Typography>
              </Container>
            </Box>
          ) : (
            <div style={{ display: "", overflowX: "auto", width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: 50,
                }}
                alignContent="center"
              >
                <h2
                  style={{
                    marginTop: "13px",
                    marginLeft: 10,
                    //width: viewName ? "" : "10%",
                  }}
                >
                  {title ? title : ""}
                </h2>
              </Box>
              <div style={{ display: "", overflowX: "auto", width: "100%" }}>
                <div
                  style={{
                    width: visibleSidebar ? "70%" : "100%",
                    paddingRight: visibleSidebar ? "3.5%" : 0,
                  }}
                >
                  <TabView
                    tabData={tabData}
                    setTabData={setTabData}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    setTabError={setTabError}
                    objectId={objectId}
                  />
                </div>
              </div>
              <ErrorBoundary>
                <Box
                  pt={1}
                  pb={3}
                  style={{ width: visibleSidebar ? "70%" : "100%" }}
                >
                  <ErrorBoundary>
                    {toolbarLoader ? (
                      <Box mt={3} mb={3}>
                        <Skeleton variant="text" width={210} />
                      </Box>
                    ) : (
                      <ToolbarRelationship
                        shouldDelete={selectedRows.length > 0}
                        rowLength={data.length}
                        selectedRows={selectedRows}
                        searchChange={searchChange}
                        attacedLink={attacedLink}
                        deattacedLink={deattacedLink}
                        diasbledAttachment={diasbledAttachment}
                        columnChooserclick={columnChooserclick}
                        excelExport={excelExport}
                        myGrid={myGrid}
                        relationshipId={relationshipId}
                      />
                    )}
                  </ErrorBoundary>
                </Box>
                <Box>
                  <ErrorBoundary>
                    <Result
                      tabValue={tabValue}
                      tabData={tabData}
                      data={data}
                      setData={setData}
                      myGrid={myGrid}
                      setTitle={setTitle}
                      objectId={objectId}
                      setAttributes={setAttributes}
                      setRelnAttributes={setRelnAttributes}
                      attributes={attributes}
                      setRelationshipId={setRelationshipId}
                      relationshipId={relationshipId}
                      setTypeName={setTypeName}
                      setTypeId={setTypeId}
                      setParentTypeId={setParentTypeId}
                      typeId={typeId}
                      stateFieldVal={stateFieldVal}
                      dateFormat={dateFormat}
                      dateTimeFormat={dateTimeFormat}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      toggleSelect={toggleSelect}
                      loader={loader}
                      setLoader={setLoader}
                      relnRef={relnRef}
                      visibleSidebar={visibleSidebar}
                      aggregateParameters={aggregateParameters}
                      showAggregate={showAggregate}
                      groupByAttribute={groupByAttribute}
                      aggregatePosition={aggregatePosition}
                      showSorting={showSorting}
                      sortingParameters={sortingParameters}
                      setAllotherSettings={setAllotherSettings}
                    />
                  </ErrorBoundary>
                </Box>
              </ErrorBoundary>
            </div>
          )}
          {showModal ? (
            <TypeObjectDialogSingleExternal
              open={showModal}
              setShowModal={setShowModal}
              typeName={typeName}
              typeId={typeId}
              attachData={handleAttachData}
              relationShipId={relationshipId}
              dateFormat={dateFormat}
              dateTimeFormat={dateTimeFormat}
              stateField={stateFieldVal}
              containerInstanceId={containerInstanceId}
              containerId={containerId}
              parentObjectId={objectId}
              relationData={relationData}
              relnAttributes={relnAttributes}
              allUsers={allUsers}
              component="generalIntegration"
              relationshipunique_multivalued={relationshipunique_multivalued}
              data={data}
            />
          ) : null}
          {deleteConfirmation ? (
            <ConfirmationDialogue
              open={deleteConfirmation}
              setOpen={setDeleteConfirmation}
              header={
                <LanguageTranslation
                  key="DELETE_SELECTED_OBJECT_MESSAGE"
                  text="Do you really want to delete selected objects?"
                />
              }
              remove={() => deleteSelectedObjects()}
            />
          ) : null}

          {visibleSidebar ? (
            <ObjectPreview
              setVisibleSidebar={setVisibleSidebar}
              visibleSidebar={visibleSidebar}
              showErrorMessage={showErrorMessage}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              setShowErrorMessage={setShowErrorMessage}
              previewData={previewData}
              dateFormat={dateFormat}
              dateTimeFormat={dateTimeFormat}
              setPreviewData={setPreviewData}
              previewLoader={previewLoader}
              setPreviewLoader={setPreviewLoader}
              setMsg={setMsg}
              setSeverity={setSeverity}
              setAlert={setAlert}
              containerId={containerId}
              containerInstanceId={containerInstanceId}
              objectId={
                selectedRows !== undefined &&
                selectedRows !== null &&
                selectedRows &&
                selectedRows.length > 0
                  ? selectedRows[0].id
                  : ""
              }
              typeId={typeId}
              width={width}
              integrationProjectName=""
              component="externalTypeView"
            />
          ) : null}
        </Container>
      </Page>
    </ErrorBoundary>
  );
};
export default View;
