import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
/*-----------------------------------Import From Material Starts here--------------------------------------------------*/
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
/*-----------------------------------Import From Material Ends here--------------------------------------------------*/
/*-----------------------------------Import From Common Files ------------------------------------*/
import GridLoader from "../../../../Loaders/GridLoader";
/*-----------------------------------Import From Common Files------------------------------------*/
/*--------------------Import From Action Starts here--------------------------------------------------*/
import { fetchAlreadyLinkedExternalRelationshipdataByObjectId } from "../../../../components/actions/integrationApiActions";
import { DynamicGrid } from "../../../../components/formatGridNData/DynamicGrid";
/*--------------------Import From Action Ends here--------------------------------------------------*/

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Result = (props) => {
  let { appId, containerId, containerInstanceId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      props.tabData &&
      props.tabData.length > 0 &&
      props.tabValue &&
      props.tabValue != ""
    ) {
      let filData = props.tabData.filter((obj) => obj.id === props.tabValue);
      if (filData && filData.length > 0) {
        props.setAttributes(filData[0].defaultColumn);
        props.setRelnAttributes(filData[0].relatedTypeDefaultColumn);
        props.setTitle(filData[0].viewName);
        props.setRelationshipId(filData[0].relationshipId);
        props.setTypeName(filData[0].connectedTypeName);
        props.setTypeId(filData[0].connectedTypeId);
        props.setParentTypeId(filData[0].typeId);

        // let valueParsed = JSON.parse(filData[0].value);
        // props.setAllotherSettings(valueParsed); 

        const dataTobeSend = {
          relationId: filData[0].relationshipId,
          objectId: props.objectId,
          containerId: containerId,
          containerInstanceId: containerInstanceId,
          typeId: parseInt(filData[0].typeId),
        };
        dispatch(
          fetchAlreadyLinkedExternalRelationshipdataByObjectId(dataTobeSend)
        ).then((result) => {
          if (result !== undefined && result !== null && result) {
            if (
              result.status !== undefined &&
              result.status !== null &&
              result.status &&
              result.status === 200
            ) {
              if (result.data) {
                props.setData(result.data);
              }
              props.setLoader(false);
            } else {
              props.setLoader(false);
            }
          }
        });
      }
    }
  }, [props.tabData, props.tabValue]);

  return (
    <Box style={{ width: props.visibleSidebar ? "70%" : "100%" }}>
      <div className="col-md-9 e-bigger e-adaptive-demo">
        <div className="e-mobile-layout">
          <div className="e-mobile-content">
            {props.loader ? (
              <GridLoader />
            ) : (
              <DynamicGrid 
                myGrid={props.myGrid}
                bodyData={props.data}
                attributes={props.attributes}
                appId={appId}
                relationshipId={props.relationshipId}
                toggleData={props.toggleSelect}
                recordClick={props.toggleSelect}
                stateField={props.stateFieldVal}
                relnRef={props.relnRef}
                component="externalTypeView"
                // widget={props.widget}
                relationTable={true}
                dateFormat={props.dateFormat}
                dateTimeFormat={props.dateTimeFormat}
                // rowsToShow={relationshipRowsToShow}
                groupByAttribute={props.groupByAttribute} 
                showAggregate={props.showAggregate}
                aggregateParameters={props.aggregateParameters}
                aggregatePosition={props.aggregatePosition}
                showSorting={props.showSorting}
                sortingParameters={props.sortingParameters}
              />
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};
export default Result;
