import React, { useState, useEffect, memo } from "react";
import {
  IconButton,
  Tooltip,
  Popover,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GroupsIcon from "@mui/icons-material/Groups";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  marginSpace: {
    marginRight: "4px",
  },
  camroot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  appbar: {
    backgroundColor: theme.palette.background.dark,
    maxHeight: "50",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    //   '&:hover': {
    //     backgroundColor: theme.palette.primary.main,
    //   },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const UserToolBarMoreOption = ({
  listtypeopenClose,
  //menuopen,
  anchorEl,
  disableDelete,
  showDelete,
  toolbarViewOpen,
  toolbarDuplicateOpen,
  toolbarViewShow,
  disableView,
  deleteData,
  columnChooserclick,
  columnChooserShow,
  excelExportShow,
  excelExport,
  importOpen,
  showImport,
  setMenuOpen,
  disableDocumentView,
  showDocumentView,
  toolbarDocumentViewOpen,
  showInlineEdit,
  setEnableInlineEdit,
  enableInlineEdit,
  inlineEditCancel,
  setBtnClicked,
  showDependancy,
  editDependancy,
  editDisable,
  callingFromGantt,
  callingFromDocumentView,
  callingFromAdminLangauge,
  anchorE2,
  setEditpermission,
  file,
  downloadFiles,
  selectFile,
  disabled,
  cardView,
  showMembers,
  disableMembers,
  handleShowMembers,
  showSelect,
  selectClicked,
  callingFromView,
  callingFromRelationship,
  layouttype,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const inlineEditOpen = () => {
    if (enableInlineEdit === true) {
      var grid;
      if (callingFromGantt === true) {
        grid = document.getElementById("ContextMenu").ej2_instances[0];
      } else if (callingFromDocumentView === true) {
        grid = document.getElementById("documentViewGrid").ej2_instances[0];
      } else if (callingFromAdminLangauge === true) {
        grid = document.getElementById("LanguageGrid").ej2_instances[0];
      } else {
        grid = document.getElementById("viewGrid").ej2_instances[0];
      }
      if (setEditpermission) {
        setEditpermission(false);
      }

      if (grid.isEdit) {
        grid.closeEdit();
        setBtnClicked(false);
      }
      //setBtnClicked(true)
      setMenuOpen(false);
      //inlineEditCancel()
    } else {
      if (setEditpermission) {
        setEditpermission(true);
      }
      setMenuOpen(false);
      setBtnClicked(false);
    }
    setEnableInlineEdit(!enableInlineEdit);
  };

  useEffect(() => {
    if (setEditpermission) {
      if (enableInlineEdit === true) {
        setEditpermission(true);
      } else {
        setEditpermission(false);
      }
    }
  }, [enableInlineEdit]);

  return (
    <Popover
      open={
        callingFromDocumentView === true ? Boolean(anchorE2) : Boolean(anchorEl)
      }
      anchorEl={callingFromDocumentView === true ? anchorE2 : anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={(e) => listtypeopenClose(e)}
    >
      <ClickAwayListener onClickAway={(e) => listtypeopenClose(e)}>
        <MenuList>
          {((callingFromView !== undefined &&
            callingFromView !== null &&
            callingFromView) ||
            (callingFromRelationship !== undefined &&
              callingFromRelationship !== null &&
              callingFromRelationship &&
              layouttype !== undefined &&
              layouttype !== null &&
              layouttype &&
              layouttype === "View")) &&
          showSelect !== undefined &&
          showSelect !== null ? (
            <MenuItem onClick={selectClicked}>
              <IconButton
                className={classes.marginSpace}
                aria-label="Select"
                size="small"
              >
                <CheckBoxIcon />
              </IconButton>
              {showSelect ? "Select" : "Hide Select"}
            </MenuItem>
          ) : null}

          {showInlineEdit ? (
            <MenuItem onClick={inlineEditOpen}>
              <IconButton
                className={classes.marginSpace}
                aria-label="Inline Edit"
                size="small"
              >
                <DriveFileRenameOutlineIcon />
              </IconButton>
              {enableInlineEdit ? "Disable Inline Edit" : "Enable Inline Edit"}
            </MenuItem>
          ) : null}

          {showDependancy ? (
            <MenuItem onClick={editDependancy} disabled={editDisable}>
              <IconButton
                className={classes.marginSpace}
                aria-label="Edit Dependancy"
                size="small"
              >
                <EditAttributesIcon />
              </IconButton>
              {"Edit Dependancy"}
            </MenuItem>
          ) : null}

          {showImport ? (
            <MenuItem onClick={importOpen}>
              <IconButton
                className={classes.marginSpace}
                aria-label="import"
                size="small"
              >
                <ImportExportIcon />
              </IconButton>
              {t(`${i18next.language}.IMPORT`) === ""
                ? "Import"
                : t(`${i18next.language}.IMPORT`)}
            </MenuItem>
          ) : null}
          {showDocumentView ? (
            <MenuItem
              disabled={disableDocumentView}
              onClick={toolbarDocumentViewOpen}
            >
              <IconButton
                className={classes.marginSpace}
                aria-label="Document view"
                size="small"
              >
                <MenuBookIcon />
              </IconButton>
              {"Document View"}
            </MenuItem>
          ) : null}

          {toolbarViewShow ? (
            <MenuItem disabled={disableView} onClick={toolbarViewOpen}>
              <IconButton
                className={classes.marginSpace}
                aria-label="view"
                size="small"
              >
                <VisibilityIcon />
              </IconButton>
              {t(`${i18next.language}.VIEW`) === ""
                ? "view"
                : t(`${i18next.language}.VIEW`)}
            </MenuItem>
          ) : null}

          {/* {toolbarViewShow ? (
            <MenuItem disabled={disableView} onClick={toolbarDuplicateOpen}>
              <IconButton
                className={classes.marginSpace}
                aria-label="Duplicate"
                size="small"
              >
                <ContentCopyIcon />
              </IconButton>
              {i18next.exists(`${i18next.language}.DUPLICATE`)
                ? t(`${i18next.language}.DUPLICATE`) === ""
                  ? "Duplicate"
                  : t(`${i18next.language}.DUPLICATE`)
                : "Duplicate"}
            </MenuItem>
          ) : null} */}

          {showMembers ? (
            <MenuItem
              disabled={disableMembers}
              onClick={(e) => handleShowMembers(e)}
            >
              <IconButton
                aria-label="delete"
                size="small"
                className={classes.marginSpace}
              >
                <GroupsIcon />
              </IconButton>
              {i18next.exists(`${i18next.language}.SHOW_MEMBERS`)
                ? t(`${i18next.language}.SHOW_MEMBERS`) === ""
                  ? "Show Members"
                  : t(`${i18next.language}.SHOW_MEMBERS`)
                : "Show Members"}
            </MenuItem>
          ) : null}

          {showDelete ? (
            <MenuItem disabled={disableDelete} onClick={(e) => deleteData(e)}>
              <IconButton
                aria-label="delete"
                size="small"
                className={classes.marginSpace}
              >
                <DeleteIcon />
              </IconButton>
              {i18next.exists(`${i18next.language}.DELETE`)
                ? t(`${i18next.language}.DELETE`) === ""
                  ? "Delete"
                  : t(`${i18next.language}.DELETE`)
                : "Delete"}
            </MenuItem>
          ) : null}

          {columnChooserShow && !cardView ? (
            <MenuItem onClick={columnChooserclick}>
              <IconButton
                aria-label="View column"
                size="small"
                className={classes.marginSpace}
              >
                <ViewWeekIcon />
              </IconButton>
              {"Column Chooser"}
            </MenuItem>
          ) : null}

          {excelExportShow && !cardView ? (
            <MenuItem onClick={excelExport}>
              <IconButton
                aria-label="excel export"
                size="small"
                className={classes.marginSpace}
              >
                <FileDownloadIcon />
              </IconButton>
              {"Excel Export"}
            </MenuItem>
          ) : null}
        </MenuList>
      </ClickAwayListener>
    </Popover>
  );
};

export default memo(UserToolBarMoreOption);
