import React, { useEffect, useState } from "react";
import { IconButton, Popover, Typography, Avatar, Chip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpIcon from "@mui/icons-material/Help";
import TextLoader from "../../../../Loaders/TextLoader";
import "../Widgets/Widget.css";

const useStyles = makeStyles()((theme) => ({
  spanColour: {
    color: "#D32F2F",
  },
  marginStyle: {
    marginTop: -4,
  },
}));
export default function InputLabel(props) {
  const { classes } = useStyles();
  const [attr, setAttr] = useState();
  const [loader, setLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open = Boolean(anchorEl);
  const openHelp = Boolean(anchorEl1);
 
  useEffect(() => {
    setLoader(true);
  }, []);

  useEffect(() => {
    if (props.attr) {
      setAttr(props.attr);
      setLoader(false);
    }
  }, [props.attr]);

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverHelpOpen = (e) => {
    setAnchorEl1(e.currentTarget);
  };

  const handlePopoverHelpClose = () => {
    setAnchorEl1(null);
  };

  const tooltipLogic = (
    <>
      <span>
        <IconButton
          aria-label="url rules"
          size="small"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={classes.marginStyle}
        >
          <InfoOutlinedIcon />
        </IconButton>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            {attr && attr.tooltipDescription ? attr.tooltipDescription : ""}
          </Typography>
        </Popover>
      </span>
    </>
  );

  const helpIconLogic = (
    <span>
      <IconButton
        aria-label="url rules"
        size="small"
        onMouseEnter={handlePopoverHelpOpen}
        onMouseLeave={handlePopoverHelpClose}
      >
        <HelpIcon />
      </IconButton>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openHelp}
        anchorEl={anchorEl1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverHelpClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>It must start with https or https</Typography>
      </Popover>
    </span>
  );

  return (
    <>
      <label className="inputLabels">
        {loader ? (
          <TextLoader />
        ) : (
          <>
            <div className="attribute-name"> {attr.name}</div>

            {attr.unitCategoryDetails &&
            attr.unitCategoryDetails.symbol &&
            props.objectType !== "View"
              ? " (" + attr.unitCategoryDetails.symbol + ") "
              : ""}
            {props.objectType &&
            props.objectType !== "View" &&
            (attr.mandatory || attr.isStateSetting) ? (
              <span className={classes.spanColour}>*</span>
            ) : null}
            {attr.enableTooltip && props.objectType !== "View"
              ? tooltipLogic
              : ""}
            {attr.dataType &&
            attr.dataType.key &&
            attr.dataType.key === "URL" &&
            props.objectType !== "View"
              ? helpIconLogic
              : ""}

            {attr?.dataType?.key === "COMMENTS" &&
            props.count !== undefined &&
            props.count &&
            props.count !== 0 ? (
              <Chip
                label={props.count !== undefined ? props.count : 0}
                color="primary"
                size="small"
                variant="Filled"
                className="chip-count"
              />
            ) : (
              ""
            )}
          </>
        )}
      </label>
    </>
  );
}
