import React, { useState, useRef, useEffect } from "react";
import * as Icons from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ColumnChooser,
  Selection,
  Toolbar,
  ExcelExport,
  Inject,
  Resize,
  Reorder,
  Sort,
  Filter,
  Edit,
  Freeze,
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
  Group,
} from "@syncfusion/ej2-react-grids";
import GridLoader from "../../Loaders/GridLoader";
import AvatarPickList from "./../../Avatars/AvatarPickList";
import AvatarUsers from "./../../Avatars/AvatarUsers";
import "../../GridHeightCss/Results.css";
import "./DynamicGrid.css";

export const DynamicGrid = ({
  toggleData,
  bodyData,
  attributes,
  relationshipId,
  stateField,
  relnRef,
  widget,
  disabledCheckbox,
  recordClick,
  appId,
  relationTable,
  dateFormat,
  dateTimeFormat,
  recordDoubleClick,
  clearRowSelection,
  rowsToShow,
  groupByAttribute,
  showAggregate,
  aggregateParameters,
  aggregatePosition,
  showSorting,
  sortingParameters,
  myGrid,
  component,
  showCheckBox,
  ...rest
}) => {
  const { t } = useTranslation();
  const [finalAttr, setFinalAttr] = useState(attributes);
  const [backDrop, setBackDrop] = useState(true);
  useEffect(() => {
    if (
      clearRowSelection !== undefined &&
      clearRowSelection !== null &&
      clearRowSelection === true
    ) {
      myGrid.current[relationshipId].clearSelection();
    }
  }, [clearRowSelection]);

  useEffect(() => {
    setBackDrop(false);
  }, [bodyData]);

  useEffect(() => {
    setFinalAttr(attributes);
  }, [attributes]);

  useEffect(() => {
    if (relnRef && relnRef.type == "columnChooser") {
      if (myGrid.current[relnRef.relnId]) {
        myGrid.current[relnRef.relnId].columnChooserModule.openColumnChooser(
          0,
          0
        );
      }
    } else if (relnRef && relnRef.type == "excelExport") {
      if (myGrid.current[relnRef.relnId]) {
        myGrid.current[relnRef.relnId].excelExport();
      }
    } else if (relnRef && relnRef.type == "search") {
      if (myGrid.current[relnRef.relnId]) {
        myGrid.current[relnRef.relnId].search(relnRef.val);
      }
    }
  }, [relnRef]);

  useEffect(() => {
    if (widget !== undefined && widget && widget.properties) {
      let properties = JSON.parse(widget.properties);

      if (properties.defaultColumn && properties.defaultColumn != "") {
        let defaultColumns = properties.defaultColumn;
        let data1 = defaultColumns.replace("[", "");
        let data2 = data1.replace("]", "");
        let split_string = data2.split(",");

        let finalAttributes =
          attributes && attributes.length
            ? attributes.filter((obj) => {
                if (obj && obj.id && split_string.includes(obj.id.toString())) {
                  return obj;
                }
              })
            : [];
        setFinalAttr(finalAttributes);
      }
    }
  }, [widget]);

  const trustTemplate = (props, attribute) => {
    let attrInternalName = attribute.internalName;

    let finalVal = [];
    if (
      attrInternalName &&
      props &&
      props[attrInternalName] &&
      typeof props[attrInternalName] == "object"
    ) {
      finalVal = props[attrInternalName].map((obj) => {
        let DynamicIcon = "";
        let DynamicColor = "white";
        if (obj.icon && obj.icon != "") {
          DynamicIcon = Icons[obj.icon];
        }
        DynamicColor = obj.color;
        return {
          DynamicIcon: DynamicIcon,
          DynamicColor: DynamicColor,
          name: obj.name,
        };
      });
    }
    return (
      <AvatarPickList
        stateField={stateField ? stateField : []}
        finalVal={finalVal}
        attrInternalName={attrInternalName}
        id={props.id}
      ></AvatarPickList>
    );
  };

  const richTxtTemplate = (props) => {
    let attrVal = [];
    let attrInternalName = "";
    let finalVal = [];

    if (
      props.column &&
      props.column.headerText &&
      attributes &&
      attributes.length > 0
    ) {
      attrVal = attributes.filter((obj) => obj.name == props.column.headerText);
    }
    if (attrVal && attrVal.length > 0 && attrVal[0].internalName) {
      attrInternalName = attrVal[0].internalName;
    }
    if (
      attrInternalName &&
      props &&
      props[attrInternalName] &&
      props[attrInternalName].length > 0
    ) {
      let numberOfImages = 0;

      const search = props[attrInternalName]?.match(/<img[^>]*>/g);

      if (search !== undefined && search && search.length !== 0) {
        numberOfImages = search.length;
      }
      finalVal = props[attrInternalName].replace(/<[^>]+>/g, " ");
      finalVal = finalVal.replace(/&nbsp;/g, " ");
      finalVal = finalVal.replace(/&amp;/g, " ");

      if (numberOfImages !== 0) {
        finalVal = finalVal.concat(
          " (",
          numberOfImages,
          " ",
          numberOfImages == 1 ? "image" : "images",
          ")"
        );
      }
    }
    return finalVal;
  };

  function empTemplate(props, attribute) {
    let empUsers = [];
    let val = "";
    let internalName = attribute.internalName;

    if (
      internalName &&
      props[internalName] &&
      props[internalName].length > 0 &&
      props[internalName][0].name
    ) {
      empUsers = props[internalName].map((obj) => {
        return {
          name: obj.name,
          color: obj.avatarColor,
          nameInitials: obj.nameInitials,
          profilePhotoUrl: obj.profilePhotoUrl,
          id: obj.id,
        };
      });
      val = empUsers[0].name.toString();

      if (attribute.dataType.key === "INTEGER") {
        if (attribute.isMasking !== undefined) {
          if (attribute.isMasking) {
            if (
              attribute.unitCategoryDetails !== undefined &&
              attribute?.unitCategoryDetails &&
              attribute?.unitCategoryDetails?.symbol !== undefined &&
              attribute?.unitCategoryDetails?.symbol
            ) {
              if (
                attribute?.unitCategoryDetails?.categoryId !== undefined &&
                attribute?.unitCategoryDetails?.categoryId
              ) {
                if (
                  attribute?.unitCategoryDetails?.categoryId === 5 &&
                  attribute?.unitCategoryDetails?.category === "Currency"
                ) {
                  return attribute?.unitCategoryDetails?.symbol + " " + val;
                } else {
                  return val + " " + attribute?.unitCategoryDetails?.symbol;
                }
              }
            } else {
              return val;
            }
          } else {
            return val;
          }
        } else {
          return "";
        }
      } else {
        return val;
      }
    }

    return (
      <AvatarUsers empUsers={empUsers} val={val} id={props.id}></AvatarUsers>
    );
  }

  const numberTemplate = (props, attribute) => {
    if (props !== undefined && props && attribute !== undefined && attribute) {
      const integerValue = props[attribute?.internalName];
      if (integerValue) {
        if (attribute && attribute.isMasking !== undefined) {
          if (attribute.isMasking) {
            if (
              attribute.unitCategoryDetails !== undefined &&
              attribute?.unitCategoryDetails &&
              attribute?.unitCategoryDetails?.symbol !== undefined &&
              attribute?.unitCategoryDetails?.symbol
            ) {
              if (
                attribute?.unitCategoryDetails?.categoryId !== undefined &&
                attribute?.unitCategoryDetails?.categoryId
              ) {
                if (
                  attribute?.unitCategoryDetails?.categoryId === 5 &&
                  attribute?.unitCategoryDetails?.category === "Currency"
                ) {
                  return (
                    attribute?.unitCategoryDetails?.symbol + " " + integerValue
                  );
                } else {
                  return (
                    integerValue + " " + attribute?.unitCategoryDetails?.symbol
                  );
                }
              }
            } else {
              return integerValue;
            }
          } else {
            return integerValue;
          }
        } else {
          return "";
        }
      } else {
        if (integerValue === "0" || integerValue === 0) {
          return 0;
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };

  const rowDataBound = (args) => {
    if (args.data.disabled) {
      args.row
        .getElementsByClassName("e-gridchkbox")[0]
        .classList.add("disablecheckbox");
      args.row
        .getElementsByClassName("e-checkbox-wrapper")[0]
        .classList.add("disablecheckbox");
    }
  };

  const rowSelecting = (args) => {
    let removedIndexLength = 0;
    if (args.data["Verified"] === false) {
      args.cancel = true; // disabled selection for disabled rows
    }
    if (args.row.length > 1) {
      args.rowIndexes.filter((rowIndex) => {
        if (
          this.grid.getRowByIndex(rowIndex).classList.contains("e-disabled")
        ) {
          args.rowIndexes.splice(rowIndex + removedIndexLength, 1);
          removedIndexLength--;
        }
      });
    }
  };

  function groupTemplate(props, attribute) {
    let groups = [];
    let val = "";
    let internalName = attribute.internalName;

    if (
      internalName &&
      props[internalName] &&
      props[internalName].length &&
      props[internalName].length > 0 &&
      props[internalName][0].name
    ) {
      groups = props[internalName].map((obj) => {
        return {
          name: obj.name,
          id: obj.id,
        };
      });

      if (groups.length !== 0) {
        if (groups.length == 0) {
          val = groups[0]?.name;
        } else {
          val = groups?.map((grp) => grp.name).join(" , ");
        }
      }
    }

    return val;
  }

  const selectionsettings = {
    persistSelection: true,
    enableSimpleMultiRowSelection: true,
    type: "Multiple",
    checkboxOnly: false,
  };

  //For Excel export
  function excelQueryCellInfo(args) {
    const longTextAttributes = finalAttr.filter(
      (val) => val.dataType.key === "LONG_TEXT"
    );
    if (longTextAttributes.length > 0) {
      longTextAttributes.map((value) => {
        if (args.column.field === value.internalName) {
          args.value = args.value
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/\&nbsp;/g, "");
        }
      });
    }
  }

  //For Showing Aggregate
  const footerSum = (props, operation) => {
    let val;
    if (
      props[operation] !== undefined &&
      props[operation] !== null &&
      typeof props[operation] === "number"
    ) {
      val = props[operation].toFixed(2);
    }
    return (
      <div
        style={{
          fontSize: 15,
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {operation}: {val}
      </div>
    );
  };

  const sortingOptions = {
    columns:
      showSorting !== undefined && showSorting !== null && showSorting
        ? sortingParameters.map((val) => {
            if (
              val.selectedSorting.dataType.key === "PICK_LIST" ||
              val.selectedSorting.dataType.key === "MULTIPLE_USERS" ||
              val.selectedSorting.dataType.key === "REFERENCED_ATTRIBUTE"
            ) {
              return {
                field: `${val.selectedSorting.internalName}.0.name`,
                direction: `${val.selectedOperation}`,
              };
            } else if (val.selectedSorting.dataType.key === "COMMENTS") {
              return {
                field: `${val.selectedSorting.internalName}.0.comment`,
                direction: `${val.selectedOperation}`,
              };
            } else {
              return {
                field: `${val.selectedSorting.internalName}`,
                direction: `${val.selectedOperation}`,
              };
            }
          })
        : [],
  };

  return (
    <Box
      id="relationBox"
      className={
        rowsToShow !== undefined
          ? "relationgrids"
          : component !== undefined && component === "externalTypeView"
          ? "gridHeightandwidth-externalTypeView"
          : "gridHeightandwidth"
      }
    >
      {backDrop ? (
        <GridLoader />
      ) : (
        <GridComponent
          dataSource={bodyData}
          statelessTemplates={["directiveTemplates"]}
          ref={(el) => (myGrid.current[relationshipId] = el)}
          height={rowsToShow !== undefined ? rowsToShow * 40 + 10 : "100%"}
          rowHeight={
            component !== undefined && component === "externalTypeView"
              ? 40
              : 60
          }
          width="100%"
          style={relationTable ? { marginBottom: "10px" } : null}
          showColumnChooser={true}
          allowResizing={true}
          allowReordering={true}
          allowSorting={true}
          allowFiltering={true}
          filterSettings={{ type: "Excel" }}
          allowExcelExport={true}
          rowSelected={toggleData}
          rowDeselected={toggleData}
          recordClick={recordClick}
          rowDataBound={rowDataBound}
          selectionSettings={selectionsettings}
          recordDoubleClick={recordDoubleClick}
          excelQueryCellInfo={excelQueryCellInfo}
          sortSettings={sortingOptions}
          allowGrouping={
            groupByAttribute !== undefined &&
            groupByAttribute !== null &&
            groupByAttribute &&
            groupByAttribute.length > 0
              ? true
              : false
          }
          groupSettings={
            groupByAttribute !== undefined &&
            groupByAttribute !== null &&
            groupByAttribute &&
            groupByAttribute.length > 0
              ? {
                  showGroupedColumn: true,
                  columns:
                    groupByAttribute !== undefined &&
                    groupByAttribute !== null &&
                    groupByAttribute &&
                    groupByAttribute.length > 0
                      ? groupByAttribute.map((groupByAttribute) => {
                          if (
                            groupByAttribute.dataType.key === "PICK_LIST" ||
                            groupByAttribute.dataType.key ===
                              "MULTIPLE_USERS" ||
                            groupByAttribute.dataType.key ===
                              "TYPE_PICK_LIST" ||
                            groupByAttribute.dataType.key === "GROUP"
                          ) {
                            return `${groupByAttribute.internalName}.0.name`;
                          } else if (
                            groupByAttribute.dataType.key === "COMMENTS"
                          ) {
                            return `${groupByAttribute.internalName}.0.comment`;
                          } else {
                            return `${groupByAttribute.internalName}`;
                          }
                        })
                      : false,
                }
              : null
          }
        >
          <ColumnsDirective>
            <ColumnDirective
              type="checkbox"
              width="100"
              maxWidth="100"
              onChange={rowSelecting}
              headerText="checkbox"
              visible={
                showCheckBox !== undefined && showCheckBox !== null
                  ? showCheckBox
                  : true
              }
            ></ColumnDirective>
            <ColumnDirective
              // this is important for keeping the selected items after any operations
              isPrimaryKey={true}
              field="id"
              headerText="id"
              showInColumnChooser={false}
              showColumnMenu={false}
              visible={false}
            ></ColumnDirective>

            {finalAttr && finalAttr.length > 0
              ? finalAttr.map((attribute, index) => {
                  if (attribute && attribute.internalName) {
                    const internalName = attribute.internalName;
                    const name = internalName + ".0.name";
                    const comment = internalName + ".0.comment";

                    if (
                      attribute.dataType.key === "PICK_LIST" ||
                      attribute.dataType.key === "MULTIPLE_USERS"
                    ) {
                      if (attribute.dataType.key === "PICK_LIST") {
                        return (
                          <ColumnDirective
                            key={attribute.internalName}
                            field={name}
                            template={(e) => trustTemplate(e, attribute)}
                            headerText={attribute.name}
                            width="200"
                          ></ColumnDirective>
                        );
                      } else if (attribute.dataType.key === "MULTIPLE_USERS") {
                        return (
                          <ColumnDirective
                            key={attribute.internalName}
                            field={name}
                            template={(e) => empTemplate(e, attribute)}
                            headerText={attribute.name}
                            width="200"
                          ></ColumnDirective>
                        );
                      } else {
                        return (
                          <ColumnDirective
                            key={attribute.internalName}
                            field={name}
                            headerText={attribute.name}
                            width="200"
                          ></ColumnDirective>
                        );
                      }
                    } else if (
                      attribute.dataType.key === "INTEGER" &&
                      (internalName == "CREATED_BY" ||
                        internalName == "MODIFIED_BY")
                    ) {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={name}
                          template={(e) => empTemplate(e, attribute)}
                          headerText={attribute.name}
                          width="200"
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key === "NUMBER") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          template={(e) => numberTemplate(e, attribute)}
                          headerText={attribute.name}
                          width="200"
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key === "TYPE_PICK_LIST") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={name}
                          headerText={attribute.name}
                          width="200"
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key === "LONG_TEXT") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          template={richTxtTemplate}
                          field={attribute.internalName}
                          headerText={attribute.name}
                          width="200"
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key == "COMMENTS") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={comment}
                          disableHtmlEncode={false}
                          headerText={attribute.name}
                          width="200"
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key == "AUTO_INCREMENT") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          headerText={attribute.name}
                          width="200"
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key === "DATE") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          headerText={attribute.name}
                          type="date"
                          format={dateFormat}
                          minWidth="120"
                          width="200"
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key === "DATETIME") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          headerText={attribute.name}
                          format={{ type: "datetime", format: dateTimeFormat }}
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key == "FILE") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          headerText={attribute.name}
                          minWidth="120"
                          width="200"
                        ></ColumnDirective>
                      );
                    } else if (
                      attribute.dataType.key === "DATETIME" &&
                      attribute.internalName === "CREATED_ON"
                    ) {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          allowEditing={false}
                          format={{ type: "datetime", format: dateTimeFormat }}
                          headerText={
                            i18next.exists(`${i18next.language}.CREATED_ON`)
                              ? t(`${i18next.language}.CREATED_ON`) === ""
                                ? "Created On"
                                : t(`${i18next.language}.CREATED_ON`)
                              : "Created On"
                          }
                        ></ColumnDirective>
                      );
                    } else if (
                      attribute.dataType.key === "DATETIME" &&
                      attribute.internalName === "MODIFIED_ON"
                    ) {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          allowEditing={false}
                          format={{ type: "datetime", format: dateTimeFormat }}
                          headerText={
                            i18next.exists(`${i18next.language}.MODIFIED_ON`)
                              ? t(`${i18next.language}.MODIFIED_ON`) === ""
                                ? "Modified On"
                                : t(`${i18next.language}.MODIFIED_ON`)
                              : "Modified On"
                          }
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key === "DATETIME") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          allowEditing={false}
                          format={{ type: "datetime", format: dateTimeFormat }}
                          headerText={
                            i18next.exists(`${i18next.language}.MODIFIED_ON`)
                              ? t(`${i18next.language}.MODIFIED_ON`) === ""
                                ? "Modified On"
                                : t(`${i18next.language}.MODIFIED_ON`)
                              : "Modified On"
                          }
                        ></ColumnDirective>
                      );
                    } else if (attribute.dataType.key === "GROUP") {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={name}
                          headerText={attribute.name}
                          template={(e) => groupTemplate(e, attribute)}
                        ></ColumnDirective>
                      );
                    } else {
                      return (
                        <ColumnDirective
                          key={attribute.internalName}
                          field={attribute.internalName}
                          headerText={attribute.name}
                          width="200"
                        ></ColumnDirective>
                      );
                    }
                  }
                })
              : null}
          </ColumnsDirective>
          {showAggregate !== undefined &&
          showAggregate !== null &&
          showAggregate === true &&
          aggregateParameters !== undefined &&
          aggregateParameters !== null &&
          aggregateParameters &&
          aggregateParameters.length > 0 &&
          aggregateParameters[0].selectedAggregate !== undefined &&
          aggregateParameters[0].selectedAggregate !== null &&
          aggregateParameters[0].selectedAggregate &&
          aggregateParameters[0].selectedAggregate.id !== undefined &&
          aggregateParameters[0].selectedAggregate.id !== null &&
          aggregateParameters[0].selectedAggregate.id &&
          aggregateParameters[0].selectedOperation !== undefined &&
          aggregateParameters[0].selectedOperation !== null ? (
            groupByAttribute !== undefined &&
            groupByAttribute !== null &&
            groupByAttribute &&
            groupByAttribute.length > 0 ? (
              aggregatePosition !== undefined &&
              aggregatePosition !== null &&
              aggregatePosition === "Footer" ? (
                <AggregatesDirective>
                  <AggregateDirective>
                    <AggregateColumnsDirective>
                      {aggregateParameters.map((val) => {
                        return (
                          <AggregateColumnDirective
                            field={`${val.selectedAggregate.internalName}`}
                            type={`${val.selectedOperation}`}
                            groupFooterTemplate={(args) =>
                              footerSum(args, `${val.selectedOperation}`)
                            }
                            footerTemplate={(args) =>
                              footerSum(args, `${val.selectedOperation}`)
                            }
                          ></AggregateColumnDirective>
                        );
                      })}
                    </AggregateColumnsDirective>
                  </AggregateDirective>
                </AggregatesDirective>
              ) : (
                <AggregatesDirective>
                  <AggregateDirective>
                    <AggregateColumnsDirective>
                      {aggregateParameters.map((val) => {
                        return (
                          <AggregateColumnDirective
                            field={`${val.selectedAggregate.internalName}`}
                            type={`${val.selectedOperation}`}
                            groupCaptionTemplate={(args) =>
                              footerSum(args, `${val.selectedOperation}`)
                            }
                            footerTemplate={(args) =>
                              footerSum(args, `${val.selectedOperation}`)
                            }
                          ></AggregateColumnDirective>
                        );
                      })}
                    </AggregateColumnsDirective>
                  </AggregateDirective>
                </AggregatesDirective>
              )
            ) : (
              <AggregatesDirective>
                <AggregateDirective>
                  <AggregateColumnsDirective>
                    {aggregateParameters.map((val) => {
                      return (
                        <AggregateColumnDirective
                          field={`${val.selectedAggregate.internalName}`}
                          type={`${val.selectedOperation}`}
                          footerTemplate={(args) =>
                            footerSum(args, `${val.selectedOperation}`)
                          }
                        >
                          {" "}
                        </AggregateColumnDirective>
                      );
                    })}
                  </AggregateColumnsDirective>
                </AggregateDirective>
              </AggregatesDirective>
            )
          ) : null}
          <Inject
            services={[
              Toolbar,
              ExcelExport,
              Selection,
              ColumnChooser,
              Resize,
              Reorder,
              Sort,
              Filter,
              Edit,
              Freeze,
              Aggregate,
              Group,
            ]}
          />
        </GridComponent>
      )}
    </Box>
  );
};
